<template>
  <div>
    <div class="sctp-bg-white" style="padding: 15px 20px">
      <div class="" style="padding: 30px;">
        <el-row :gutter="60">
          <template v-for="nav in navArray">
            <el-col :span="6">
              <router-link :to="nav.url">
                <div class="nav-card" :key="nav.url">
                  <div class="flex flex-center">
                    <el-image
                      :src="nav.img"
                      style="width: 70px;height: 65px;"
                      fit="contain"
                    ></el-image>
                  </div>
                  <div class="title">{{ nav.title }}</div>
                </div>
              </router-link>
            </el-col>
          </template>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div style="padding-top: 60px;">
              <div class="sctp-bold mg-b10">用户发布须知</div>
              <div class="fc-info" style="line-height: 2.4;">
                <div>1、请确保您发布的内容合法合规，涉及侵权内容将会被移除</div>
                <div>2、实名、店铺认证通过后才能上架源码、文档、设计</div>
                <div>3、一旦发现认证信息存在伪造或是虚假行为，我们有权下架您发布的所有源码、文档、设计及服务</div>
                <div>4、出售交易源码、设计、文档、服务等平台会收取一定比例佣金</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "publish",
  data() {
    return {
      navArray: [
        {url: '/shop/release/sourcecode', title: '发布源码', img: '/images/icon/code.png'},
        {url: '/shop/release/design', title: '发布设计', img: '/images/icon/design.png'},
        {url: '/shop/release/techdocs', title: '发布文档', img: '/images/icon/doc.png'},
        {url: '/shop/release/serivce', title: '发布服务', img: '/images/icon/service.png'},
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.nav-card {
  background-color: #f7dba5;
  border-radius: 5px;
  color: white;
  padding: 15px 0;
  cursor: pointer;

  &:hover {
    background-color: $main-color;
  }

  .title {
    text-align: center;
    margin-top: 10px;
    font-size: 16px;
  }
}
</style>
